
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        




































































































































































































































































































































































@import '@/styles/_colors.scss';

.validation {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-grey-100;
}

.validation__valid {
  padding: 0.5rem;
  background: $color-green;
  border-radius: 2px;
}

.validation__loading {
  padding: 0.5rem;
  border-radius: 2px;
}

.validation__error {
  padding: 0.5rem;
  background: $color-red;
  border-radius: 2px;
}

.validation-check {
  margin-top: 0.5rem;
}
